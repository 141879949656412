<template>
  <!-- 订单页面,分为已付款,待收货,已完成 -->
  <nut-tabs v-model="state.tab11value">
    <nut-tab-pane title="已付款">
      <nut-empty image="empty" description="无内容"></nut-empty>
    </nut-tab-pane>
    <nut-tab-pane title="待收货">
      <nut-empty image="empty" description="无内容"></nut-empty>
    </nut-tab-pane>
    <nut-tab-pane title="已完成">
      <nut-empty image="empty" description="无内容"></nut-empty>
    </nut-tab-pane>
  </nut-tabs>
</template>

<script>
import { ref } from 'vue';
const state = ref({
  tab11value: 0
});
export default {
  name: 'Order',
  setup() {
    return {
      state
    };
  }
};
</script>